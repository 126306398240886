import { HomeBody, HomeWrapper } from './styles'
import Modal from '../../components/Modal/Modal'
import { useAppDispatch } from '../../redux/hooks'
import { openModal, setModalStatus } from '../../redux/slices/modalSlice'
import { useCallback, useEffect } from 'react'
import Flow from '../../components/Flow/Flow'
import { Button, GrayButton } from '../../components'
import { useSearchParams } from 'react-router-dom'
import { FooterBlock } from '../../components/Flow/steps/NewStep/styles'
import { isMobile } from 'react-device-detect'
import { FlowStatus } from '../../constants/flow'
import { requestOfflineUrl, requestOnlineUrl } from '../../components/Flow/steps/Request/Request'

const Home = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const startHandler = useCallback(() => {
    dispatch(openModal({ reset: true }))
  }, [dispatch])

  useEffect(() => {
    if (searchParams.has('started')) {
      startHandler()
    }
  }, [searchParams, startHandler])

  const openRequest = (subStatus: string, url: string) => {
    if (isMobile) {
      window.open(url)
    } else {
      dispatch(openModal({ reset: true }))
      dispatch(setModalStatus({ status: FlowStatus.REQUEST, background: '#f2f2f7', subStatus }))
    }
  }

  const openOnlineRequest = () => {
    openRequest('online', requestOnlineUrl)
  }

  const openOfflineRequest = () => {
    openRequest('offline', requestOfflineUrl)
  }

  return (
    <>
      <HomeWrapper>
        <HomeBody>
          <img src="./img/logo_Turkmenistan.png" alt='Logo' />
          <h1>Türkmenistanyň Dijital Milli Şahsyýeti</h1>
          <p>
            In Türkmenistanyň Dijital Şahsyýetnamasyňyzy Folio gapjygyňyzda almak üçin, çalt şahsyýet tassyklamasy geçirmeli bolar.
          </p>
          <p>
            In order to obtain your Turkmenistan Digital ID in your Folio wallet you will need to perform a quick identity verification.
          </p>
          <FooterBlock>
            <Button onClick={startHandler}>Start Demo</Button>
            <GrayButton onClick={openOnlineRequest}>Request Online (w/o QR)</GrayButton>
            <GrayButton onClick={openOfflineRequest}>Request Offline (w QR)</GrayButton>
          </FooterBlock>
        </HomeBody>
      </HomeWrapper>
      <Modal>
        <Flow />
      </Modal>
    </>
  )
}

export default Home
