import { DataBlock, FooterBlock, LoadingOverlay, NewStepWrapper } from '../NewStep/styles'
import { Flex1 } from '../../../Modal/styles'
import { Button } from '../../../Button/styles'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { selectIdNumber, selectLayout, selectPhoto, setErrorCode, setResult } from '../../../../redux/slices/dataSlice'
import { sendData } from '../../../../services/data.service'
import { selectModalBackground, setModalStatus } from '../../../../redux/slices/modalSlice'
import { FlowStatus } from '../../../../constants/flow'
import { Loader } from '../../../Loader/styles'

const SendData = () => {
  const [processing, setProcessing] = useState(false)
  const dispatch = useAppDispatch()
  const photo = useAppSelector(selectPhoto)
  const idNumber = useAppSelector(selectIdNumber)
  const layout = useAppSelector(selectLayout)
  const background = useAppSelector(selectModalBackground)

  const sendHandler = async () => {
    if (photo) {
      setProcessing(true)
      try {
        const result = await sendData(idNumber, layout, photo)
        setTimeout(() => {
          if (result.status === 'SUCCESS') {
            dispatch(setResult({ result }))
            dispatch(setModalStatus({ status: FlowStatus.ADD_TO_WALLET, showHeader: false }))
          } else {
            console.log(result)
            dispatch(setErrorCode({ errorCode: result.code }))
            dispatch(setModalStatus({ status: FlowStatus.ERROR }))
          }
        }, 500)
      } catch (e: any) {
        console.log(e)
        dispatch(setErrorCode({ errorCode: e.code }))
        dispatch(setModalStatus({ status: FlowStatus.ERROR }))
      }
      console.log('send')
    }
  }

  return (
    <NewStepWrapper>
      <h2>Send to Ministry of Internal Affairs</h2>
      <p>The following information will be matched with your document and photo on file with your issuing authority.</p>
      <DataBlock>
        <div>
          <div className='data divider'>
            <label>Personal Number</label>
            <span>{idNumber}</span>
          </div>
        </div>
        <div>
          <div className='image'>{photo && <img src={photo} alt='photo' />}</div>
          <div className='data'>
            <span>Your Photo</span>
          </div>
        </div>
      </DataBlock>
      <Flex1 />
      <FooterBlock background={background}>
        <Button key={new Date().getTime()} onClick={sendHandler}>
          Send
        </Button>
      </FooterBlock>
      {processing && (
        <LoadingOverlay>
          <Loader />
          Sending to Ministry of Internal Affairs...
        </LoadingOverlay>
      )}
    </NewStepWrapper>
  )
}

export default SendData
