import React from 'react'
import {
  closeModal,
  selectModalBackground,
  selectModalOpened,
  selectModalPrevStatus,
  selectModalShowHeader,
  setModalStatus
} from '../../redux/slices/modalSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { CloseButton, Flex1, ModalBody, ModalContainer, ModalHeader, ModalWrapper } from './styles'
import { FlowStatus } from '../../constants/flow'

type Props = {
  children: React.ReactNode
}
const Modal = ({ children }: Props) => {
  const dispatch = useAppDispatch()
  const opened = useAppSelector(selectModalOpened)
  const showHeader = useAppSelector(selectModalShowHeader)
  const prevStatus = useAppSelector(selectModalPrevStatus)
  const background = useAppSelector(selectModalBackground)
  const closeHandler = () => {
    dispatch(closeModal())
  }
  const prevHandler = () => {
    dispatch(setModalStatus({ status: FlowStatus.NEW }))
  }

  if (!opened) return null

  return (
    <ModalWrapper>
      <ModalContainer background={background}>
        {showHeader && (
          <ModalHeader>
            {prevStatus ? (
              <CloseButton onClick={prevHandler}>
                <img src='./img/prev.svg' alt='close' />
              </CloseButton>
            ) : (
              <img src='./img/logo_Turkmenistan.png' alt='Turkmenistan' />
            )}
            <Flex1 />
            <CloseButton onClick={closeHandler}>
              <img src='./img/close.svg' alt='close' />
            </CloseButton>
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
      </ModalContainer>
    </ModalWrapper>
  )
}

export default Modal
