import { FooterBlock, NewStepWrapper, Notification } from '../NewStep/styles'
import { Flex1 } from '../../../Modal/styles'
import { Button } from '../../../Button/styles'
import { useAppSelector } from '../../../../redux/hooks'
import { selectResult } from '../../../../redux/slices/dataSlice'
import { useEffect, useState } from 'react'
import { generatePreview } from '../../../../services/preview.service'

const AddToWallet = () => {
  const [preview, setPreview] = useState('')
  const result = useAppSelector(selectResult)

  useEffect(() => {
    generatePreview(result.token).then((result) => {
      setPreview(result.thumbnail)
    })
  }, [])

  useEffect(() => {
    if (preview) {
      const previewElement = document.querySelector('.image-preview')
      if (!previewElement) return
      const frame = document.createElement('iframe')
      frame.setAttribute('id', 'preview-frame')
      //const placeholder = preview.querySelector('.placeholder');
      //placeholder.remove();
      previewElement.innerHTML = ''
      previewElement.appendChild(frame)
      if (frame.contentWindow !== null) {
        frame.style.backgroundColor = 'transparent'
        frame.style.width = 'calc(100% - 40px)'
        const cw = frame.contentWindow
        frame.addEventListener('load', function (e) {
          frame.height = '' + (cw.document.body.scrollHeight + 2)
        })
        cw.document.open()
        cw.document.write(preview)
        cw.document.close()
      }
    }
  }, [preview])

  const addHandler = () => {
    if (result?.documentLink) {
      window.open(result?.documentLink)
    }
  }
  return (
    <NewStepWrapper>
      <div className='image-preview'>
        <div className='placeholder'>
          <div className='animated-background'></div>
        </div>
      </div>
      <h2 style={{ marginTop: '16px' }}>Turkmenistan National ID Card</h2>
      <p>Securely present your identity with your phone.</p>
      <Flex1 />
      <FooterBlock>
        <Notification>
          <span>
            By using Folio you agree to its&nbsp;
            <a href='https://folio.id'>Terms & Conditions</a>
          </span>
        </Notification>
        <Button key={new Date().getTime()} onClick={addHandler}>
          Add to Government Wallet
        </Button>
      </FooterBlock>
    </NewStepWrapper>
  )
}

export default AddToWallet
